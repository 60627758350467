var add_form_template = require('./add_form.twig');

import { GoogleAddressAutocomplete } from "../../helpers/GoogleAddressAutocomplete"
import { Format } from "../../helpers/Format"
import { Dropdown } from '../../components/Dropdown'
import { CustomModal } from '../../components/customModal'

export class AddForm
{
    constructor(meetingPhase, attendeeData, meetingData) {
        this.meetingPhase = meetingPhase
        this.attendeeData = attendeeData
        this.meetingData = meetingData //incase we need it, Mastercourse i'm looking at you
        this.attendeeData.att_type = (this.attendeeData.att_type || 'target').toLowerCase();
        this.fields = app.appData.layout.attendance.form_fields[this.attendeeData.att_type][this.meetingPhase]
        this.requiredFields = this.getRequiredFields();
        this.updateFields();
        this.format = new Format();
        this.irep_validations = app.appData.configs.ATTENDEE_FIELD_IREP_VALIDATION ?? null;
        this.check_invalid_email = app.appData.configs?.INVALID_EMAIL_CHECK ?? false;
        this.npiValidation = app.appData.configs?.CHECK_NPI_VALIDATION ?? false;

        let templateData = { data: add_form_template }

        this.dom = $(twig(templateData).render({
            'appData': app.appData,
            'meetingPhase': this.meetingPhase,
            'meetingData': this.meetingData,
            'attendeeData': this.attendeeData,
            'fields': this.fields,
            'int_company_name': app.appData.supportInfo.int_company_name,
            'degree_title': app.appData.layout.attendance.degree_title,
            'has_meals': app.appData.serviceTypes[this.meetingData.service_id] ? app.appData.serviceTypes[this.meetingData.service_id].has_meals : false
        }))

        this.dom.on('shown.bs.modal',  () => {
            if (this.fields['address1']) {
                new GoogleAddressAutocomplete("attendance_add_via_form_address")
            }
        })

        this.dom.find('#address1').on('focus', () => {
            $('#state_license, #license_country, #license_state').attr('autocomplete', 'no');
        })

        this.dom.on('hidden.bs.modal',  () => {
            $('#add_form_modal').remove(); 
            this.modal.dispose();
        })

        this.setupDropdowns()
        this.setRequiredFields()
        this.setFormVals()
        this.checkRequired()

        this.dom.find('.postalcode').mask('99999-9999')
        this.dom.find('.phonenumber').mask('(999) 999-9999')

        this.dom.find('.attendee-val-message').on('click', () => {
            $('.attendee-val-message').hide();
        })

        this.dom.find("#prev-addresses").on('change', () => {
            let currCountry = this.dom.find('#country').val()
            let data = this.dom.find("#prev-addresses :selected").data()
            Object.keys(data).forEach((key) => {
                if(key != 'state' || (key == 'state' && currCountry == data['country']))
                {
                    this.dom.find("#" + key + '.address-field').val(data[key])
                }
                else
                {
                    this.buildStateDD(this.dom.find("#state-dd-container"), 'state', data['country'], data[key])
                }
            });
        })

        this.dom.find(".country-dd").on('change', (event) => {
            let data = $(event.target).data()
            let countryAbbrv = $(event.target).val();

            this.buildStateDD(this.dom.find("#" + data.stateDdContainer), data.stateDdId, countryAbbrv)
            this.dom.find('[name="address1"], [name="city"]').trigger('keyup');
        });

        this.dom.on('keyup', '[name="address1"], [name="city"]', () => {
            this.format.setPatternTitleForAddressElements($(event.target),this.dom.find('[name="country"]').val());
        });

        this.dom.on('change', '.pattern-valid', () => {
            $("#add_form")[0].reportValidity();
        })

        this.dom.find("#add_form input, #add_form select").on('change blur', (event) => {
            var targetValue = $(event.target).val();

            if (targetValue !== null && targetValue !== undefined) {
                $(event.target).val(targetValue.trim());
            }

            this.checkRequired()

            if (this.check_invalid_email) {
                this.checkInvalidEmail();
            }
        })

        this.dom.find('#is_fed_emp').on('change', (event) => {
            let val = $(event.target).val()
            if(val ==  1) {
                this.dom.find('#is_state_emp').val("0").trigger('change')
            }
        });

        this.dom.find('#is_state_emp').on('change', (event) => {
            let val = $(event.target).val()
            if(val ==  1) {
                this.dom.find('#is_fed_emp').val("0").trigger('change')
            }
        });

        this.dom.find('#license-state-dd-container').on('change', '#license_state', () => {
            this.setRequiredForLicenseStateNumber();
        });

        this.dom.find('#license_number').on('change', () => {
            this.setRequiredForLicenseStateNumber()
        });

        this.dom.find('#received_article_reprint_yes').on('change', () => {
            if (this.dom.find('#received_article_reprint_yes').prop('checked')) {
                this.dom.find('#article_reprint_count').prop('required', true).addClass('required is-invalid')
            }
            this.dom.find('.article_reprint_count-container').show()
        })

        this.dom.find('#received_article_reprint_no').on('change', () => {
            if (this.dom.find('#received_article_reprint_no').prop('checked')) {
                this.dom.find('#article_reprint_count').prop('required', false).removeClass('required is-invalid')
            }
            this.dom.find('.article_reprint_count-container').hide()
        });

        if((this.fields['email'] ?? false)  && (this.fields['wwid'] ?? false) && this.fields['email']['required'] && this.fields['wwid']['required']){
            this.dom.find('input[name="email"]').on('keyup', () => {
                this.validateEitherEmailOrWwidField();
            });
            this.dom.find('input[name="wwid"]').on('keyup', () => {
                this.validateEitherEmailOrWwidField();
            });
        }

        if (this.check_invalid_email) {
            this.dom.find('input[name="email"]').on('keyup', () => {
                this.checkInvalidEmail();
            });
        }

        this.dom.find('#degree').on('change', (event) => {
            let otherDegrees = app.appData.layout.attendance.other_degrees
            let degreeElem = this.dom.find('#degree')
            let field = (app.appData.layout.attendance.degree_title ? '#title' : '#degree_other')
            let container = (app.appData.layout.attendance.degree_title ? '.title-container' : '.degree-other-container')
            let prescriberReqFields = ['specialty', 'license_state', 'state_license']

            if (app.appData.layout.attendance.disassociate_degree ?? false) {
                prescriberReqFields.splice(prescriberReqFields.indexOf('specialty'), 1);
            }

            if(otherDegrees.includes(degreeElem.val())) {
                if(degreeElem.prop('required')) {
                    this.dom.find(field).prop('required', true).addClass('required is-invalid')
                }

                this.dom.find(container).show()
            } else {
                this.dom.find(field).prop('required', false).removeClass('required is-invalid').val('')
                this.dom.find(container).hide()
            }

            const is_prescriber = this.dom.find("option:selected", event.target).data('is_prescriber') == 1
            for(const field of prescriberReqFields) {
                if( this.fields[field] && this.fields[field]['required'] && is_prescriber ) {
                    this.dom.find('#' + field).prop('required', true).addClass('required is-invalid')
                } else {
                    this.dom.find('#' + field).prop('required', false).removeClass('required is-invalid')
                }
            }

            if(!(app.appData.configs.DISASSOCIATE_DEGREE_NPI ?? false) && !(app.appData.layout.attendance.disassociate_degree ?? false))
            {
                if(
                    this.fields['npi_num']
                    && this.fields['npi_num']['required']
                    && ($("option:selected", event.target).data('npi_required') == 1)
                ) {
                    this.dom.find('#npi_num').prop('required', true).addClass('required is-invalid')
                }
                else {
                    this.dom.find('#npi_num').prop('required', false).removeClass('required is-invalid')
                }
            }

            if (this.fields["degree_npi_license_flag"] ?? false) {
                const npi_required = this.dom.find("option:selected", event.target).data('npi_required') == 1
                const license_required = this.dom.find("option:selected", event.target).data('license_required') == 1
                if (npi_required) {
                    this.dom.find('#npi_num').prop('required', true).addClass('required is-invalid')
                } else {
                    this.dom.find('#npi_num').prop('required', false).removeClass('required is-invalid')
                }

                if (license_required) {
                    this.dom.find('#state_license').prop('required', true).addClass('required is-invalid')
                } else {
                    this.dom.find('#state_license').prop('required', false).removeClass('required is-invalid')
                }
            }
          
        })

        this.dom.find('#npi_na').on('change', () => {
            if(this.dom.find('#npi_na').prop('checked')) {
                this.dom.find('#npi_num').prop('required', false).prop('disabled', true).removeClass('required is-invalid').val('')
            }
            else {
                this.dom.find('#npi_num').prop('disabled', false).prop('required', this.fields['npi_num']['required'])
                if(this.dom.find('#npi_num').prop('required')) {
                    this.dom.find('#npi_num').addClass('required is-invalid')
                }
            }
        })

        this.dom.find("#npi_num").on('change', (event) => {
            var npi_number = $(event.target).val();

            if (this.npiValidation && npi_number !== null && npi_number !== undefined) {
                npi_number = npi_number.toString().trim();
                this.checkInvalidNpiNumber(npi_number);
            }
        })

        if (this.attendeeData.att_type.toLowerCase() === "target") {
            if (this.irep_validations) {
                var firstnameInput = this.dom.find('#firstname');
                var lastnameInput = this.dom.find('#lastname');
                
                firstnameInput.on('blur', function() {
                    var firstNameValue = firstnameInput.val();
                    if(/[-'A-Za-z]+/.test(firstNameValue)){
                        firstnameInput.attr('title','Invalid - !@#$%^&*()_+=`[]{}\\|;:",.<>/?1234567890 special characters and numbers not allowed')
                    }
                });
        
                lastnameInput.on('blur', function() {
                    var lastNameValue = lastnameInput.val();
                    if(/[-'A-Za-z]+/.test(lastNameValue)){
                        lastnameInput.attr('title','Invalid - !@#$%^&*()_+=`[]{}\\|;:",.<>/?1234567890 special characters and numbers not allowed')
                    }
                });
            }
        }
        
        this.dom.find("#add_form").on('submit', (event) => {
            event.preventDefault();
            let formData = {};
            this.dom.find("#add_form input, #add_form select").each((index, elem) => {
                if ($(elem).prop('type') == 'radio') {
                    formData[$(elem).attr('name')] = $('input[name=' + $(elem).attr('name') + ']:checked').val();
                } else if ($(elem).prop('type') != 'checkbox' || $(elem).is(':checked')) {
                    formData[$(elem).attr('name')] = $(elem).val();
                }
            });
            if (formData['license_number']) {
                formData['state_license'] = formData['license_number'];
                delete formData['license_number'];
            }
            // city, zip, state, validation
            let cityInput = this.dom.find('#city');
            let stateInput = this.dom.find('#state');
            let zipInput = this.dom.find('#zip');

            let countryValue = this.dom.find('#country').val().toLowerCase(); 
            if (countryValue === 'us') {
                let isValid = true; 

                let fieldsToCheck = [
                    { input: cityInput, name: 'City' },
                    { input: stateInput, name: 'State' },
                    { input: zipInput, name: 'Zip Code' }
                ];

                fieldsToCheck.forEach(function(field) {
                    let fieldValue = formData[field.input.attr('id').toLowerCase()]; 
                    if (!fieldValue) {
                        field.input.prop("required", true);
                        field.input.addClass('required is-invalid');
                        field.input.attr('title', 'Please fill the ' + field.name + ' field');
                        isValid = false;
                    }
                });

                if (!isValid) {
                    return false; 
                }
            }
            // city, zip, state, validation

        
            if(this.attendeeData.att_type.toLowerCase() == "target"){
                if (this.irep_validations) {
                    var firstnameInput = this.dom.find('#firstname');
                    var lastnameInput = this.dom.find('#lastname');
                    var address2nameInput = this.dom.find('#address2');
                    var isValid = true; 
            
                    // Validate first name
                    var firstNameValue = formData['firstname'];
                    if (/^Mc/i.test(firstNameValue) && firstNameValue.charAt(2) !== firstNameValue.charAt(2).toUpperCase()) {
                        firstnameInput.prop("required", true);
                        firstnameInput.addClass('required is-invalid');
                        firstnameInput.attr('title', `Third letter of First Name should be in upper case when First Name starts with ‘Mc’. Example: McLoud`);
                        isValid = false;
                    }
                    if (/\b(?:Dr|MD)\b/i.test(firstNameValue)) {
                        firstnameInput.prop("required", true);
                        firstnameInput.addClass('required is-invalid');
                        firstnameInput.attr('title', `First Name Cannot contain Dr or MD `);
                        isValid = false;
                    }
            
                    // Validate last name
                    var lastNameValue = formData['lastname'];
                    
                    if (/\b(?:Dr|MD)\b/i.test(lastNameValue)) {
                        lastnameInput.prop("required", true);
                        lastnameInput.addClass('required is-invalid');
                        lastnameInput.attr('title', `Last Name Cannot contain Dr or MD `);
                        isValid = false;
                    }
                    if (lastNameValue.length < 2 && lastNameValue !== "" && lastNameValue.charAt(0) !== 'U' && lastNameValue.charAt(0) !== 'O') {
                        lastnameInput.prop("required", true);
                        lastnameInput.addClass('required is-invalid');
                        lastnameInput.attr('title',"Last Name <2, only 'U' or 'O' allowed ");
                        isValid = false;
                    }
                    
                    if (/^Mc/i.test(lastNameValue) && lastNameValue.charAt(2) !== lastNameValue.charAt(2).toUpperCase()) {
                        lastnameInput.prop("required", true);
                        lastnameInput.addClass('required is-invalid');
                        lastnameInput.attr('title', `Third letter of Last Name should be in upper case when Last Name starts with ‘Mc’. Example: McLoud`);
                        isValid = false;
                    }
                    
                    // Validate address2
                    var address2nameValue = formData['address2'];
                    if(address2nameValue !== "" && address2nameValue !== null){
                    if (!address2nameValue || (!/[a-zA-Z]/.test(address2nameValue) || !/\d/.test(address2nameValue))) {
                        address2nameInput.prop("required", true);
                        address2nameInput.addClass('required is-invalid');
                        address2nameInput.attr('title', `Invalid - Enter at least one number and one letter.`);
                        isValid = false;
                    }
                    const pattern = /^[a-zA-Z0-9\s]+$/;

                    if (address2nameValue && !pattern.test(address2nameValue)) {
                        address2nameInput.prop("required", true);
                        address2nameInput.addClass('required is-invalid');
                        address2nameInput.attr('title', 'Invalid - ~!@$%^&*()_+=`[]{}\\|;:"<>? special characters not allowed');
                        isValid = false; // Mark as invalid
                    } 
            
                    if (address2nameValue && /\b(?:Apt|APT|APt|ApT|apt|apT|aPt|aPT|bOX|bOx|boX|BoX|box|Box|BOX|Box)\b/i.test(address2nameValue)) {
                        address2nameInput.prop("required", true);
                        address2nameInput.addClass('required is-invalid');
                        address2nameInput.attr('title', `No Apt and Box allowed `);
                        isValid = false;
                    }
                }
            
                    // If form is not valid, prevent form submission
                    if (!isValid) {
                        return false;
                    }
                }
            }

            if (this.check_invalid_email) {
              let emailInput = this.dom.find("#email");
              let emailValue = formData["email"];
              let isValid = true;

              if (!this.validateEmail(emailValue)) {
                emailInput.prop("required", true);
                emailInput.addClass("required is-invalid");
                emailInput.attr("title", `Please enter valid email`);
                isValid = false;
              }

              if (!isValid) {
                return false;
              }
            }
        
            // Proceed with form submission if all validations pass
            $.ajax({
                url: 'api/attendance/checkinvdegspec',
                method: 'get',
                data: {
                    degree: formData.degree,
                    specialty: formData.specialty,
                    meeting_id: $("#meeting_id").val()
                }
            }).done((data) => {
                if (data.success === false && !$("#is_staff").length) {
                    this.invalidMod = new CustomModal({
                        title: data.title,
                        content: "<p>" + data.message + "</p>",
                        buttons: [{
                                text: app.appData.layout.attendance.invalid_continue,
                                id: 'continuebtn',
                                close: true,
                                class: 'btn-primary',
                                action: () => {
                                    this.submitFormData(event, formData);
                                }
                            },
                            {
                                text: "Cancel",
                                id: 'cancelbtn',
                                close: true,
                                class: 'btn-primary',
                            }
                        ]
                    });
        
                    this.invalidMod.open();
                } else {
                    this.submitFormData(event, formData);
                }
            });
        });
        

        if (app.appData.configs.ADDRESS_FIELDS_READONLY ?? false) {
            this.dom.on('keypress', '#country, #city, #zip', function(event) {
                if ($(this).prop('readonly')) {
                    event.preventDefault();
                    return;
                }
            });

            this.dom.find('#country, #city, #zip').attr('onmousedown', 'return false;').attr('readonly', false).attr('onkeydown', 'event.preventDefault()').addClass('readonly-field');

            this.dom.on('input', '[name="address1"]', () => {
               
                this.dom.find('[name="city"], [name="state"], [name="zip"]').val('');
                this.dom.find('[name="country"]').val('US');

                let cityInput = this.dom.find('#city');
                let stateInput = this.dom.find('#state');
                let zipInput = this.dom.find('#zip');
                cityInput.attr('onmousedown', 'return false;').attr('readonly', false).attr('onkeydown', 'event.preventDefault()').prop('required',true);
                stateInput.attr('onmousedown', 'return false;').attr('readonly', false).attr('onkeydown', 'event.preventDefault()').prop('required',true);
                zipInput.attr('onmousedown', 'return false;').attr('onkeydown', 'event.preventDefault()').attr('readonly', false).prop('required',true);
                
            });
            
        }
        this.dom.on('click', '[name="address1"]', function() {
            this.dom.find('#city').prop('required',false).removeClass('pattern-valid'); 
            this.dom.find('#state').prop('required',false).removeClass('pattern-valid'); 
            this.dom.find('#zip').prop('required',false).removeClass('pattern-valid'); 
            return false;
        }.bind(this));
       
        this.dom.on('input change', '#address1, select', function() {
            this.dom.find('#city').prop('required', true).addClass('pattern-valid'); 
            this.dom.find('#state').prop('required', true).addClass('pattern-valid'); 
            this.dom.find('#zip').prop('required', true).addClass('pattern-valid'); 
        }.bind(this));

        this.modal = new bootstrap.Modal(this.dom, {
            backdrop: 'static',
            keyboard: false,
            focus: false
        });

        this.modal.show();
    }

    submitFormData(event, formData){

        $.ajax({
            url: 'api/attendance/attendee',
            method: 'post',
            data:  {
                data: formData,
                meeting_id: $("#meeting_id").val()
            }
        }).done((data) => {
            if(data.success)
            {
                app.pages.attendance.dom.trigger('participantAdded', [event, formData]);
                this.modal.hide();
                if(typeof app.appData.layout.meeting_request.approval_email_after_min_att !== 'undefined'
                    && app.appData.layout.meeting_request.approval_email_after_min_att) {
                    this.sendApprovalEmail();
                }
            } else if (data.message) {
                $('.attendee-val-message > span').html(data.message + '<br>Click this message to hide it.');
                $('.attendee-val-message').show();
            } else {
                app.pages.attendance.dom.trigger('failedAddByEmail', [event, formData]);
                this.modal.hide();
            }
        })

    }
    setFormVals()
    {
        Object.keys(this.attendeeData).forEach((key) => {
            if(key == 'state')
            {
                this.buildStateDD(this.dom.find("#state-dd-container"), 'state', this.attendeeData['country'], this.attendeeData[key])
            }
            else
            {
                this.dom.find('#' + key).val(this.attendeeData[key])
            }
        })
    }

    buildStateDD(stateContainer, ddName, countryCode, selectedStateCode)
    {
        countryCode = countryCode || 'US'
        selectedStateCode = selectedStateCode || ''
        $.ajax({
            url    : 'api/states/' + countryCode,
            method  : 'get'
        }).done((data) => {
            stateContainer.empty();
            this.stateDD = new Dropdown(data)
                .addClass('input-group-text')
                .addClass('form-control-sm')
                .addClass('address-field')
                .addClass(this.fields[ddName]['required'] ? 'required is-invalid' : '')
                .label('Select a state...')
                .required(this.fields[ddName]['required'] || false)
                .draw(stateContainer, 'alpha2', 'name', ddName)

            if(data.length == 1)
            {
                stateContainer.find('select').val(data[0].alpha2)
            }

            if(selectedStateCode != '')
            {
                 stateContainer.find('select').val(selectedStateCode)
            }

            if (app.appData.configs.ADDRESS_FIELDS_READONLY ?? false) {
                stateContainer.find('select').addClass('readonly-field');
                stateContainer.find('select').prop('readonly', true);
                stateContainer.find('select').attr('onmousedown', 'return false;');
                stateContainer.find('select').attr('onkeydown', 'event.preventDefault()');
            }
        });
    }

    getPreviousAddresses()
    {
        return $.ajax({
                url     : 'api/attendance/getattendeeaddresses',
                method  : 'get',
                data    : {
                    meeting_id: $('#meeting_id').val(),
                }
            })
    }

    setupDropdowns() {
        let specialtyDD = new Dropdown(app.format.getSortedSpecialties())
        specialtyDD
            .addClass('input-group-text')
            .addClass('form-control-sm')
            .label('Select a specialty...')
            .draw(this.dom.find("#specialty-dd-container"), 'code', 'short_name', 'specialty')
        if(this.fields['specialty'] && this.fields['specialty']['autofill']) {
            this.dom.find('#specialty').select2({
                placeholder:'Select a specialty...',
                dropdownParent: this.dom.find('.modal-body')
            })
        }

        let degrees = app.format.getSortedDegrees();
        degrees = degrees.filter(degree => degree.active == '1')
        let degreeDD = new Dropdown(degrees)
        degreeDD
            .addClass('input-group-text')
            .addClass('form-control-sm')
            .label('Select a degree...')
            .draw(this.dom.find("#degree-dd-container"), 'degree_abbrv', 'degree_desc', 'degree', ['npi_required', 'is_prescriber', 'license_required'])
        if(this.fields['degree'] && this.fields['degree']['autofill']) {
            this.dom.find('#degree').select2({
                placeholder:'Select a degree...',
                dropdownParent: this.dom.find('.modal-body')
            })
        }

        let titleDD = new Dropdown(app.appData.titles)
        titleDD
            .addClass('input-group-text')
            .addClass('form-control-sm')
            .label('Select a title...')
            .draw(this.dom.find("#title-dd-container"), 'code', 'name', 'title')

        let countryDD = new Dropdown(app.appData.countries)
        countryDD
            .addClass('input-group-text')
            .addClass('form-control-sm')
            .addClass('address-field')
            .addClass('country-dd')
            .label('Select a country...')
            .draw(this.dom.find("#country-dd-container"), 'alpha2', 'name', 'country')
        this.dom.find("#country-dd-container > select").data({'state-dd-container':'state-dd-container', 'state-dd-id':'state'})

        this.buildStateDD(this.dom.find("#state-dd-container"), 'state', 'US')

        if(this.fields.license_state !== undefined) {
            let licenseCountryDD = new Dropdown(app.appData.countries)
            licenseCountryDD
                .addClass('input-group-text')
                .addClass('form-control-sm')
                .addClass('country-dd')
                .label('Select a country...')
                .draw(this.dom.find("#license-country-dd-container"), 'alpha2', 'name', 'license_country')
            this.dom.find("#license-country-dd-container > select").data({'state-dd-container':'license-state-dd-container', 'state-dd-id': 'license_state'}).val('US')

            this.buildStateDD(this.dom.find("#license-state-dd-container"), 'license_state', 'US')
        }

        if (this.fields.license_state_number !== undefined) {
            let stateContainer = this.dom.find("#license-state-dd-container")
            let countryCode = 'US'
            $.ajax({
                url: 'api/states/' + countryCode,
                method: 'get'
            }).done((data) => {
                stateContainer.empty();
                this.stateDD = new Dropdown(data)
                    .addClass('input-group-text')
                    .addClass('form-control-sm')
                    .addClass('address-field')
                    .label('Select a state...')
                    .draw(stateContainer, 'alpha2', 'name', 'license_state')

                if (data.length == 1) {
                    stateContainer.find('select').val(data[0].alpha2)
                }
            });
        }

        this.getPreviousAddresses().done((data) => {
            let prevAddressDD = this.dom.find("#prev-addresses")
            prevAddressDD.empty();
            prevAddressDD.append('<option value=""></option>')
            data.data.forEach((address) => {
                let option = $(twig({ data: "<option>{{ address1 }} {{address2}} {{city}}, {{state}} {{zip}}</option>" }).render(address))
                option.data(address)
                prevAddressDD.append(option);
            })
        });

        let exact_match = false;
        let term = '';
        this.dom.find('#ship_to').select2({
            ajax: {
                url: "api/attendance/searchshipto",
                dataType: "json",
                data: (params) => {
                    term = params.term.toUpperCase();

                    return {
                        query: params.term
                    };
                },
                method: "GET",
                processResults: (data) => {
                    Object.keys(data).forEach((key) => {
                        data[key]['id'] = data[key]['ship_to'].toUpperCase();
                        data[key]['text'] = data[key]['customer_name'];

                        if(data[key]['id'] === term) {
                            exact_match = true;
                        }
                    });

                    return {
                        results: data
                    }
                },
                delay: 300
            },
            templateResult: (data) => {
                if(data.ship_to
                && data.customer_name) {
                    return  data.ship_to + ', ' + data.customer_name + ', ' + data.line_1 + ', ' + data.city + ', ' + data.state + ', ' + data.zip;
                }
            },
            templateSelection: (data) => {
                if(data.ship_to
                    && data.customer_name) {
                    return data.ship_to + ', ' + data.customer_name + ', ' + data.line_1 + ', ' + data.city + ', ' + data.state + ', ' + data.zip;
                } 
                else {
                    return "Please enter a ship to # or customer name to search"
                }
            },
            tags: true,
            createTag: (params) => {
                let tag = exact_match ? null : { id: params.term, text: params.term };

                exact_match = false;

                return tag;
            },
            minimumInputLength: 2,
            placeholder: "Please enter a ship to # or customer name to search",
            dropdownParent: this.dom.find('.modal-body')
        });

        this.dom.find('#ship_to').on('select2:open', () => {
            document.querySelector('.select2-search__field').focus();
        });
    }

    setRequiredFields() {
        Object.keys(this.fields).forEach((fieldId) => {
            let field = this.fields[fieldId]

            if(field['required'])
            {
                this.dom.find('#' + fieldId).prop('required', true).addClass('required is-invalid')
            }
        });
    }

    updateFields() {
        Object.keys(this.fields).forEach((fieldName) => {
            if (this.requiredFields[fieldName] !== undefined) {
                this.fields[fieldName].required = this.requiredFields[fieldName];
            }
        });
    }

    checkRequired() {
        this.dom.find("#add_form .required").each((index, elem) => {
            if ($(elem).val()) {
                $(elem).removeClass('is-invalid')
            }
            else
            {
                $(elem).addClass('is-invalid')
            }
        })
    }

    sendApprovalEmail() {
        $("#meeting_id").val()
        return $.ajax({
            url     : 'api/meeting/sendapprovalemail',
            method  : 'post',
            data    : {
                meeting_id: $("#meeting_id").val()
            }
        })
    }

    setRequiredForLicenseStateNumber() {

        let state = this.dom.find('#license_state').val();
        let number  = this.dom.find('#license_number').val(); 

        if(((state !== null) && (number == "")) || ((state == null) && (number !== ""))) {
            this.dom.find('#license_number').prop('required', true).addClass('required is-invalid');
            this.dom.find('#license_state').prop('required', true).addClass('required is-invalid');
        } else {
            this.dom.find('#license_number').prop('required', false).removeClass('required is-invalid');
            this.dom.find('#license_state').prop('required', false).removeClass('required is-invalid');

        }

    }

    getRequiredFields() {
        let requiredFields = {}
        let requiredKey = this.meetingPhase + "_required"
        let type = "target"
        if(app.appData.projects[this.meetingData.project_id].program_types[this.meetingData.program_type_id].configuration.manage_attendance.questions) {
            requiredFields = app.appData.projects[this.meetingData.project_id].program_types[this.meetingData.program_type_id].configuration.manage_attendance.questions[type][requiredKey] || {};
        }

        return requiredFields
    }

    validateEitherEmailOrWwidField() {
        let email = this.dom.find('input[name="email"]').val().trim();
        let wwid = this.dom.find('input[name="wwid"]').val().trim();
        if(email!=="" || wwid!=="") {
            this.dom.find('input[name="email"]').prop('required', false).removeClass('required is-invalid');
            this.dom.find('input[name="wwid"]').prop('required', false).removeClass('required is-invalid');
        } else {
            this.dom.find('input[name="email"]').prop('required', true).addClass('required');
            this.dom.find('input[name="wwid"]').prop('required', true).addClass('required');
        }
    }

    validateEmail(email) {
        let emailData = JSON.parse(app.appData.configs?.INVALID_EMAILS ?? {});
        let extension = email.toLowerCase().split('.').pop();
        let invalidEmails = emailData.invalid_emails ?? [];
        const regex = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');
        let validEmailExtensions = emailData.valid_email_extensions ?? [];
        let extensionFlag = !(validEmailExtensions.length > 0 && !validEmailExtensions.includes(extension));
        return regex.test(String(email).toLowerCase()) && !invalidEmails.includes(email.toLowerCase()) && extensionFlag;
    }

    checkInvalidEmail() {
        let email = this.dom.find('input[name="email"]').val().trim();

        if (!this.validateEmail(email)) {
            this.dom.find('input[name="email"]').prop('required', true).addClass('required is-invalid').attr("title", `Please enter valid email`);
        } else {
            this.dom.find('input[name="email"]').prop('required', false).removeClass('required is-invalid').removeAttr("title");
        }
    }

    validateNpiNumber(npi) {
        npi = npi.split('');
        let checkBit = parseInt(npi.pop()), npiDouble = [], npiUnchanged = [];
        npi.reverse();

        for (let i = 0; i < npi.length; i++) {
            if ((i % 2) === 0) {
                npiDouble.push((npi[i] * 2));
            }

            if ((i % 2) === 1) {
                npiUnchanged.push(npi[i]);
            }
        }

        npiDouble = npiDouble.toString().replace(/,/g, '');
        npiUnchanged = npiUnchanged.toString().replace(/,/g, '');
        let total = npiUnchanged + npiDouble, totalSum = 0;

        for (let i = 0; i < total.length; i++) {
            totalSum += parseInt(total.charAt(i));
        }

        totalSum += 24; // Magic Number
        var final = Math.ceil((totalSum) / 10) * 10;
        return ((final - totalSum) === checkBit);
    }

    checkInvalidNpiNumber(npi_number) {
        if (!(/^[\d]{10}$/.test(npi_number)) || !this.validateNpiNumber(npi_number)) {
            this.showNpiError("NA, N/A and an Invalid NPI# are not accepted.");
            return false;
        }

        this.hideNpiError();
        return true;
    }

    showNpiError(message) {
        this.dom.find('#npi_num_error').text(message).show();
        this.dom.find('#npi_num_error').addClass('text-danger');
        this.dom.find('#add_form button[type="submit"]').prop('disabled', true);
        this.dom.find('input[name="npi_num"]').prop('required', true).addClass('required is-invalid').attr("title", "NA, N/A and an Invalid NPI# are not accepted.");
    }

    hideNpiError() {
        this.dom.find('#npi_num_error').hide();
        this.dom.find('#npi_num_error').removeClass('text-danger');
        this.dom.find('#add_form button[type="submit"]').prop('disabled', false);
        this.dom.find('input[name="npi_num"]').prop('required', false).removeClass('required is-invalid').removeAttr("title");
    }
}
