var target_search_template = require('./target_search_modal.twig');

import { Dropdown } from '../../components/Dropdown'
import { CustomModal } from "../../components/customModal";

export class TargetSearch
{
    renderFilterDDs() {
        let specialtyDD = new Dropdown(app.format.getSortedSpecialties())
        specialtyDD
           .label('Specialty')
           .draw(this.dom.find("#target_search_specialty-dd-container"), 'code', 'short_name', 'specialty_code')
        this.dom.find('#target_search_specialty-dd-container > select').addClass("input-group-text")

        let degreeDD = new Dropdown(app.format.getSortedDegrees())
        degreeDD
           .label('Degree')
           .draw(this.dom.find("#target_search_degree-dd-container"), 'degree_abbrv', 'degree_abbrv', 'degree')
        this.dom.find('#target_search_degree-dd-container > select').addClass("input-group-text")

        let statesDD = new Dropdown(app.appData.states)
        statesDD
            .label('State')
            .draw(this.dom.find("#target_search_state_dd-container"), 'alpha2', 'name', 'state')
        this.dom.find('#target_search_state_dd-container > select').addClass("input-group-text")

    }

    reloadDatatable() {
        this.datatable.ajax.reload();
    }

    setFilterData() {
        this.filterData = {};
        this.dom.find("#form_filter_targets input, #form_filter_targets select").each((index ,elem) => {
            this.filterData[$(elem).attr('name').replace('target_search_', '')] = $(elem).val();
        });
        this.filterData['attendee_code'] = app.appData.projects[this.meetingData.project_id].program_types[this.meetingData.program_type_id].configuration.manage_attendance.only_search_ace_targets ? 'ace' : '';
    }

    /*
        Require minimum filter criteria to be entered before enabling the search button. By default, that will remain set to just the "lastname" input.

        - Text inputs must have at least 2 characters
        - Select inputs must have a valid option chosen
        - An empty array on ref_config.SEARCH_UNIVERSE_MINIMUM_CRITERIA means at least 1 input has search data meeting the above rules
        - The config array can otherwise contain any combination of input names as defined in the ipt_validation object below
    */
    canSearch()
    {
        let ipt_validation = {
            customer_id:    (this.dom.find('#target_search_customer_id').val() ?? '').trim().length > 1,
            firstname:      (this.dom.find('#target_search_firstname').val() ?? '').trim().length > 1,
            lastname:       (this.dom.find('#target_search_lastname').val() ?? '').trim().length > 1,
            city:           (this.dom.find('#target_search_city').val() ?? '').trim().length > 1,
            state:          !['', null].includes((this.dom.find('#state').val() ?? '')),
            degree:         !['', null].includes((this.dom.find('#degree').val() ?? '')),
            specialty:      !['', null].includes((this.dom.find('#specialty_code').val() ?? ''))
        };

        let criteria = JSON.parse(app.appData.configs.SEARCH_UNIVERSE_MINIMUM_CRITERIA ?? '["lastname"]');
        if(criteria.length > 0){
            // If any rules have been specified, make sure each of them passes their check
            let pass_count = 0;
            for(let ipt in criteria){
                if(ipt_validation[criteria[ipt]] && ipt_validation[criteria[ipt]] === true){
                    pass_count++;
                }
            }

            if(pass_count == criteria.length){
                return true;
            }
        }else{
            // If no rules have been specified, make sure at least 1 input contains data to search with
            for(let key in ipt_validation){
                if(ipt_validation[key] === true){
                    return true;
                }
            }
        }

        return false;
    }

    reset() {
        let topicSources = this.dom.find("[name='sources']").val()
        this.dom.find('#form_filter_targets')[0].reset()
        this.dom.find("[name='sources']").val(topicSources)
        this.dom.find('.add_attendee_via_form_button').hide()
        this.dom.find('#target_filter button').prop('disabled', true)
        this.dom.find('.attendee-val-message').hide()
        this.dom.find('#error-message').empty().hide()
    }

    hcpComplianceCheck(targetId){
        // console.log("running HCP Compliance check with " + targetId);
        let response = $.ajax({
            url     : 'api/attendance/compliancecheck',
            method  : 'get',
            dataType: 'json',
            async   : false,
            data    : {
                target_id: targetId,
                meeting_id: $("#meeting_id").val()
            }
        });
        return response.responseJSON;
    }

    validateNpi(npi) {
        if (npi !== "") {
          npi = npi.split("");
          let checkBit = parseInt(npi.pop()),
            npiDouble = [],
            npiUnchanged = [];
          npi.reverse();

          for (let i = 0; i < npi.length; i++) {
            if (i % 2 === 0) {
              npiDouble.push(npi[i] * 2);
            }

            if (i % 2 === 1) {
              npiUnchanged.push(npi[i]);
            }
          }

          npiDouble = npiDouble.toString().replace(/,/g, "");
          npiUnchanged = npiUnchanged.toString().replace(/,/g, "");
          let total = npiUnchanged + npiDouble,
            totalSum = 0;

          for (let i = 0; i < total.length; i++) {
            totalSum += parseInt(total.charAt(i));
          }

          totalSum += 24; // Magic Number
          let final = Math.ceil(totalSum / 10) * 10;
          return final - totalSum === checkBit;
        } else {
            return true;
        } 
    }

    checkNpiNumberValidation(errorElement, inputValue) {
        let regex = /^\d{10}$/;
        if (!regex.test(String(inputValue).toLowerCase()) && inputValue !== "") {
            errorElement.text('Invalid NPI number, please enter correct NPI.').show();
            return false;
        } else if (!this.validateNpi(inputValue)) {
            errorElement.text('Invalid NPI number, please enter correct NPI.').show();
            return false;
        } else {
            errorElement.empty().hide()
            return true;
        }
    }

    showRestrictedTargetModal() {
        let restrictedMsg = app.appData.configs.RESTRICTED_TARGET_MESSAGE ?? 
        "Registration could not be completed. The selected target is not eligible for attendance.";
        new CustomModal({
            title: 'Restricted Target.',
            content: `<p>${restrictedMsg}</p>`,
        }).open();
    }

    constructor(meetingData) {
        this.meetingData = meetingData
        let twoHourIntervalCheck = this.meetingData.two_hour_interval_check
        let show_add_form = true
        let target_columns = []

        if(typeof app.appData.projects[this.meetingData.project_id].program_types[this.meetingData.program_type_id].configuration.manage_attendance.show_add_form !== 'undefined') {
            show_add_form = app.appData.projects[this.meetingData.project_id].program_types[this.meetingData.program_type_id].configuration.manage_attendance.show_add_form
        }

        this.dom = $(twig({ data: target_search_template }).render({
            show_add_form: show_add_form,
            show_cust: app.appData.layout.attendance.customer_filter,
            table_data: app.appData.layout.attendance.target_table,
            topic_sources: this.meetingData.topic_sources,
            notice_msg: app.appData.layout.attendance.notice_msg ?? false
        }))

        for (var val in app.appData.layout.attendance.target_table) {
            target_columns.push({'data': app.appData.layout.attendance.target_table[val].data})
        }

        target_columns.push({
            'data': 'target_id',
            render : (data, type, row) => {
                if(row.can_add)
                {
                    return '<button type="button" class="btn btn-sm btn-primary add-target"  data-target-id="' + row.target_id + '" data-customer-id="' + row.customer_id + '" data-address-id="' + row.address_id + '" data-address-index="' + row.address_index + '" data-name="' +  row.firstname + ' ' +  row.lastname + '" data-ship_to="' + row.ship_to + '" data-inv-deg="' + row.invalid_degree + '"data-inv-spec="' + row.invalid_specialty + '"> Add</button>';
                }
                else
                {
                    return '';
                }
            }
        })

        this.datatable = this.dom.find("#datatable_targets").DataTable({
            "processing": true,
            "serverSide": true,
            "ordering": false,
            "ajax": {
                "url": "api/attendance/searchtargets",
                "data": (d) => {
                    d.data = this.filterData;
                    d.meeting_id = $("#meeting_id").val();
                }
            },
            "columns": target_columns,
            "pagingType": "full_numbers",
            "width" : "100%",
            "dom": '<"row"<"col-6"l><"col-6"<"row">>><t>pri',
            "deferLoading": 1,
            "pageLength": 25,
            "scrollCollapse": true,
            "language": {
                "loadingRecords": 'Please wait while we fetch your results.',
                "emptyTable":     "No Results",
                "processing": 'Please wait while we fetch your results.'
            },
            "drawCallback": function() {
                app.convertDatatableToCard("datatable_targets");
            }
        });

        this.renderFilterDDs();

        // Enable/Disable the search button based on some criteria
        this.dom.find('#target_search_customer_id, #target_search_firstname, #target_search_lastname, #target_search_city').on('keyup', () => {
            let errorElement = this.dom.find("#error-message");
            let flag = (errorElement.text().trim().length > 0 || !this.canSearch());
            this.dom.find("#target_filter button").prop("disabled", flag);
        });

        this.dom.find("#target_search_npi_num").on("keyup", (e) => {
            let errorElement = this.dom.find("#error-message");
            let flag = (!this.checkNpiNumberValidation(errorElement, e.target.value) || !this.canSearch());
            this.dom.find("#target_filter button").prop("disabled", flag);
          });

        this.dom.find('#state, #degree, #specialty_code').on('change', () => {
            let errorElement = this.dom.find("#error-message");
            let flag = (errorElement.text().trim().length > 0 || !this.canSearch());
            this.dom.find("#target_filter button").prop("disabled", flag);
        });

        this.dom.find('#form_filter_targets').on('submit', (e) => {
            e.preventDefault()
            this.dom.find('.attendee-val-message').hide()
            this.setFilterData()

            this.datatable.ajax.reload(() => {
                this.dom.find('.add_attendee_via_form_button').show();
            });
        });

        this.dom.find("#datatable_targets tbody").on('click', '.add-target', (e) => {
            let button = $(e.target);
            let targetData = {...this.datatable.row($(e.target).parents('tr')).data(), ...button.data()}
            targetData.preRegister = false;

            if (targetData.restricted) {
                // Handle pre-registration restriction based on config
                if (parseInt(app.appData.configs.RESTRICT_PRE_REGISTRATION) ?? false) {
                    if (!twoHourIntervalCheck) {
                        this.showRestrictedTargetModal();
                        return false;
                    }
                    targetData.preRegister = true;
                } else {
                    this.showRestrictedTargetModal();
                    return false;
                }
            }

            if(this.meetingData.hcp_compliance_check){
                let hcpComplianceRes = this.hcpComplianceCheck(targetData.targetId)
                if(hcpComplianceRes.success == false){
                    this.hcpComplianceFailed = new CustomModal({
                        title: 'ABS Compliance Check',
                        content: "<p>" + hcpComplianceRes.message + "</p>",
                    });
                    this.hcpComplianceFailed.open();
                    return false;
                }
            }

            if(targetData.invDeg || targetData.invSpec){
                let invItems = [];
                let invMessage = '';
                if(targetData.invDeg){
                    invItems.push("degree");
                }
                if(targetData.invSpec){
                    invItems.push("specialty");
                }
                let invString = invItems.join(' and ');

                if(app.appData.layout.attendance.invalid_message){
                    invMessage = "<p>" + app.appData.layout.attendance.invalid_message + "</p>";
                }
                else{
                    invMessage = "<p>The selected HCP has an unapproved " + invString + ". If you would like to move forward adding this HCP an exception will be created.</p>";
                }

                this.invalidMod = new CustomModal({
                    title: 'Invalid ' + invString,
                    content: invMessage,
                    buttons: [
                        {
                            text: app.appData.layout.attendance.invalid_continue,
                            id: 'continuebtn',
                            close:true,
                            class: 'btn-primary',
                            action: () => {
                               button.remove()
                                targetData.att_type = '';

                                app.pages.attendance.dom.trigger('targetInviteClicked', [e, targetData]);
                           }

                        },
                        {
                            text: "Cancel",
                            id: 'cancelbtn',
                            close:true,
                            class: 'btn-primary'


                        }]
                });

                this.invalidMod.open();
            }
            else{
                button.remove()
                targetData.att_type = '';

                app.pages.attendance.dom.trigger('targetInviteClicked', [e, targetData]);
            }
        });

        this.dom.find('.add_attendee_via_form_button').on('click', (event) => {
            this.modal.hide();
            app.pages.attendance.dom.trigger('addViaFormClicked', [event, this.filterData])
        })

        this.modal = new bootstrap.Modal(this.dom, {backdrop: 'static', keyboard: false, show: false});
    }
}
